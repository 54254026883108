
@import './variables';
@import './fonts';

*:not(.ni):not(.fa):not(.fab):not(.fas):not(.far) {
    font-family: 'Poppins', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
    color: #000 !important;
}


// Cursor
.cursor-pointer { cursor: pointer !important; }


// Fontsize
.fontsize-8 { font-size: 8px !important; }
.fontsize-9 { font-size: 9px !important; }
.fontsize-10 { font-size: 10px !important; }
.fontsize-11 { font-size: 11px !important; }
.fontsize-12 { font-size: 12px !important; }
.fontsize-13 { font-size: 13px !important; }
.fontsize-14 { font-size: 14px !important; }
.fontsize-15 { font-size: 15px !important; }
.fontsize-16 { font-size: 16px !important; }
.fontsize-17 { font-size: 17px !important; }
.fontsize-18 { font-size: 18px !important; }
.fontsize-19 { font-size: 19px !important; }
.fontsize-20 { font-size: 20px !important; }
.fontsize-21 { font-size: 21px !important; }
.fontsize-22 { font-size: 22px !important; }
.fontsize-23 { font-size: 23px !important; }
.fontsize-24 { font-size: 24px !important; }


// Colors
.color-white { color: #fff !important; }
.color-black { color: #000 !important; }
.color-grey-1 { color: $color-grey-1 !important; }
.color-grey-2 { color: $color-grey-2 !important; }
.color-grey-3 { color: $color-grey-3 !important; }
.color-grey-4 { color: $color-grey-4 !important; }
.color-primary { color: $color-primary !important; }
.color-primary-2 { color: $color-primary-2 !important; }
.color-red { color: $color-red !important; }
.color-red-2 { color: $color-red-2 !important; }
.color-yellow { color: $color-yellow !important; }
.color-green { color: $color-green !important; }
.color-blue { color: $color-blue !important; }
.color-orange { color: $color-orange !important; }
.color-bandag { color: $color-bandag !important; }

.background-transparent { background-color: transparent !important; }
.background-white { background-color: #fff !important; }
.background-grey-1 { background-color: $color-grey-1 !important; }
.background-grey-2 { background-color: $color-grey-2 !important; }
.background-grey-3 { background-color: $color-grey-3 !important; }
.background-grey-4 { background-color: $color-grey-4 !important; }
.background-primary { background-color: $color-primary !important; }
.background-primary-2 { background-color: $color-primary-2 !important; }
.background-yellow { background-color: $color-yellow !important; }
.background-green { background-color: $color-green !important; }
.background-blue { background-color: $color-blue !important; }
.background-orange { background-color: $color-orange !important; }
.background-red { background-color: $color-red !important; }
.background-red-2 { background-color: $color-red-2 !important; }
.background-bandag { background-color: $color-bandag !important; }


.display-block { display: block !important; }


// Align
.textalign-left { text-align: left !important; }
.textalign-center { text-align: center !important; }
.textalign-right { text-align: right !important; }


// Flex properties
.justifycontent-spacebetween { justify-content: space-between !important; }
.alignitems-center { align-items: center !important; }


// Weight
.fontweight-400 { font-weight: 400 !important; }
.fontweight-600 { font-weight: 600 !important; }
.fontweight-bold { font-weight: bold !important; }


// Padding
.padding-none { padding: 0px !important; }
.paddingtop-none { padding-top: 0px !important; }
.paddingbottom-none { padding-bottom: 0px !important; }
.paddingright-none { padding-right: 0px !important; }
.paddingleft-none { padding-left: 0px !important; }
.paddingX-none { padding-right: 0px !important; padding-left: 0px !important; }
.paddingY-none { padding-top: 0px !important; padding-bottom: 0px !important; }

.paddingright-6px { padding-right: 6px !important; }
.paddingleft-6px { padding-left: 6px !important; }
.paddingright-8px { padding-right: 8px !important; }
.paddingleft-8px { padding-left: 8px !important; }
.paddingright-10px { padding-right: 10px !important; }
.paddingleft-10px { padding-left: 10px !important; }
.paddingright-12px { padding-right: 12px !important; }
.paddingleft-12px { padding-left: 12px !important; }

@media screen and (max-width: $screen-size-lg) {
    .padding-none-lg { padding: 0px !important; }
}


// Margin
.margin-none { margin: 0px !important; }


// Width
.width-200px { width: 200px !important; }
.width-260px { width: 260px !important; }
.width-320px { width: 320px !important; }
.width-400px { width: 400px !important; }

.maxwidth-200px { max-width: 200px !important; }
.maxwidth-260px { max-width: 260px !important; }
.maxwidth-320px { max-width: 320px !important; }
.maxwidth-400px { max-width: 400px !important; }


// Height
.height-full { height: 100% !important; }


// Radius
.borderradius-default { border-radius: $borderradius-default !important; }





// MUI Components

//Typography
.MuiTypography-root {
    letter-spacing: 0 !important;
}

// Tooltip
.MuiTooltip-tooltip {
    padding: 12px !important;    
}

// Button
.MuiButton-root {
    text-transform: initial !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
}

// Static DatePicker
.MuiPickerStaticWrapper-content {
    background-color: transparent !important;
}

// Calendar Picker
.MuiCalendarPicker-root {
    .MuiPickersDay-root {
        &.Mui-selected {
            background-color: $color-primary !important;
            color: #fff;
        }
    }
}


// FormControl

.MuiFormGroup-root {
    .MuiRadio-root {
        &.Mui-checked {
            color: $color-primary !important;
        }
    }

    .MuiTypography-root {
        font-size: 14px !important;
    }
}

