
@import '../../../assets/scss/variables';

.categories-container {
    align-items: center;
    margin-bottom: 40px;

    .box-buttons {
        display: flex;
        justify-content: flex-start;

        .category-button {
            margin-left: 12px;
            flex: 1;
            background-color: #fff;
            color: $color-grey-4;
            text-transform: initial;
            border-radius: 20px;
            font-size: 12px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
            transition: 0.2s;
    
            .category-icon {
                margin-right: 4px;
                font-size: 24px;
            }
    
    
            &.selected {
                color: #fff;
                background-color: $color-primary;
            }
        }
    }
}



.backdrop-fullscreenpreview {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    z-index: 999;


    .box-header {
        width: 100%;
        height: 60px;
        display: flex;
        padding: 0px 20px;
        justify-content: flex-end;
        align-items: center;

        .iconbutton-closetrigger,
        .iconbutton-downloadtrigger {
            margin-left: 20px;
            color: #fff;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
            z-index: 99;

            &.iconbutton-closetrigger {
                background-color: $color-primary !important;
            }

            &.iconbutton-downloadtrigger {
                background-color: $color-blue !important;
            }
        }
    }

    .box-content {
        width: 100%;
        padding: 0px 20px;
        height: calc(100vh - 60px - 120px);
        display: flex;
        flex-direction: row;
        overflow: auto;

        .box-changeindex {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconbutton-changeindex {
                color: #fff;
            }
        }

        .box-attachment {
            flex: 10;
            padding: 20px;

            .attachmentcard-fullscreenpreview {
                height: 100% !important;
                background-color: transparent !important;

                .MuiImageListItem-img {
                    object-fit: contain !important;
                }
            }

            .attachmentfile-fullscreenpreview {
                width: 100%;
                height: 100%;
            }
        }
    }

    .box-footer {
        width: 100%;
        height: 120px;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 12px;
        display: -webkit-box;
        overflow: auto;

        .attachmentcard-smallpreview {
            height: 100% !important;
            width: auto;
            max-width: 160px !important;
            margin: 0px 8px;
            opacity: 0.6;
            transition: 0.2s;

            &.actived, &:hover {
                opacity: 1;
            }
        }
    }
}


@media screen and (max-width: $screen-size-lg) {
    .categories-container {
        .box-buttons {
            flex-direction: column;
    
            .category-button {
                margin-left: 0px;
                min-width: 45%;
                margin-bottom: 8px;
            }
        }
    }

    .backdrop-fullscreenpreview {
        .box-header {
            padding: 0px 12px;

            .iconbutton-closetrigger,
            .iconbutton-downloadtrigger {
                margin-left: 12px;
            }
        }
    
        .box-content {
            padding: 0px 12px;
        }
    }
}