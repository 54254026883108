
@import '../../../assets/scss/variables';


.results-container {
    .buttons-container {
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
    
        .box-buttons {
            display: flex;
            justify-content: center;
    
            .item-button {
                flex: 1;
                margin-left: 12px;
                min-width: 120px;
                background-color: #fff;
                color: $color-grey-4;
                text-transform: initial;
                border-radius: 20px;
                font-size: 14px;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
                transition: 0.2s;
        
                .item-icon {
                    margin-right: 4px;
                    font-size: 24px;
                }
        
                &.selected {
                    color: #fff;
                    background-color: $color-primary;
                }
            }
        }
    }
    
    div.card {
        height: 100%;

        div.chart {
            height: 300px;
        }
    }
}


@media screen and (max-width: $screen-size-lg) {
    .buttons-container {
        .box-buttons {
            flex-direction: column;
    
            .item-button {
                margin-left: 0px;
                min-width: 45%;
                margin-bottom: 8px;
            }
        }
    }
}