
@import '../../assets/scss/variables';



.iconbutton-notificationtrigger {
    margin-right: 12px !important;
    color: $color-yellow !important;
    position: relative;

    &.displaying-header {
        display: none;
    }


    .text-count {
        position: absolute;
        top: -4px;
        right: -4px;
        border-radius: 100%;
        background-color: $color-primary;
        color: #fff;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9px;
        border: 3px solid $content-background-color;
        z-index: 99;
        transition: 0.2s;
        transform: scale(0);

        &.actived {
            transform: scale(1);
        }
    }
}


@media screen and (max-width: 767px) {
    .iconbutton-notificationtrigger {
        &.displaying-header {
            display: inline-flex !important;
        }
        
        &:not(.displaying-header) {
            display: none !important;
        }
    }
}






.notifications-menu {
    .notifications-items {
        max-height: 60vh;
        overflow-x: hidden;
        overflow-y: auto;

        .notification-menuitem {
            display: flex;
            color: $color-grey-2;
            border-top: 1px solid $color-grey-3;
    
            &:first-child {
                border-top: none;
            }
    
            .notification-icon {
                width: 40px;
                height: 40px;
            }
    
            .notification-text {
                flex: 1;
                padding: 12px 24px;
    
                .notification-title {
                    color: $color-primary;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    white-space: break-spaces;
                }
    
                .notification-subtitle {
                    color: $color-grey-4;
                    font-size: 12px;
                    line-height: 13px;
                    white-space: break-spaces;
                    margin: 4px 0px 4px 0px;
                    max-width: 100%;
                }
    
                .notification-createdat {
                    color: $color-grey-4;
                    font-size: 10px;
                    white-space: break-spaces;
                }
            }
        }
    }

    .notifications-all {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 20px;
        border-top: 1px solid $color-grey-3;
        background-color: $color-grey-3;
        cursor: pointer;
    }
}