@import '../../assets/scss/variables';

.percentagechart-container {
    width: 100%;
    height: 68px;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 20px;

    &.with-divisor {
        border-bottom: 1px solid $color-grey-1;
    }

    .percentage-box {
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        width: 90px;

        .percentage-text, .percentage-simbol {
            font-size: 36px;
            line-height: unset;
        }

        .percentage-simbol {
            margin-left: 4px;
            font-size: 18px;
        }
    }

    .title-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1;

        .title-text {
            padding: 0px 4px 0px 0px;
            font-size: 11px;
            line-height: unset;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .subtitle-text {
                font-size: 11px;
                line-height: unset;
            }
        }

        .chart-box {
            width: 100%;
            height: 16px;
            background-color: $color-grey-3 !important;
            position: relative;
            border-radius: 4px;

            .progress-bar {
                height: 100%;
                border-radius: 4px;
                background-color: $color-primary;
                transition: 0.4s;
            }
        }
    }
}