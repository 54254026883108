@import '../../../assets/scss/variables';


.infotask-tooltip {
    border-radius: $borderradius-default !important;
    padding: 0px !important;
    width: 320px !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3) !important;

    .infotask-title {
        text-align: center !important;
        border-top-left-radius: $borderradius-default !important;
        border-top-right-radius: $borderradius-default !important;
    }
}
