.action-plan-container {
  padding-top: 10px;
}

.action-plan__nav-item__nav-link {
  cursor: pointer;
}

.action-plan__nav-item__nav-link.active {
  /* background-color: #fff!important; */
}
