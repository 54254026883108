
@import '../../../assets/scss/variables';

.storeprofile-container {
    .card-users {
        width: 100%;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-grey-1;
        border-left: 10px solid $color-primary;
        border-radius: $borderradius-card;
        margin: 12px 0px 0px 0px;
        padding: 0px 16px;
    }
}


@media screen and (max-width: $screen-size-lg) {
    .storeprofile-container {
        .card-users {
            .phone-box {
                display: none;
            }
        }
    }
}